<template>
  <div id="CoursesDetailLink">
    <div class="top">
      <video style="width: 100%;" v-if="seriesDetail.videourl" :src="seriesDetail.videourl" autoplay controls="controls" loop="loop"></video>
      <video style="width: 100%;" v-else :src="seriesDetail.url" autoplay controls="controls" loop="loop"></video>
    </div>
    <div class="describe">
      <p>{{seriesDetail.title}}</p>
      <p>{{seriesDetail.created_at}}</p>
      <p>{{seriesDetail.summary}}</p>
    </div>
    <div class="content" v-html="seriesDetail.content">

    </div>
  </div>
</template>
<script>
export default {
  name: 'CoursesDetailLink',
   data(){
    return{
      courseId:'',
      seriesDetail:''
    }
  },
  mounted(){
    this.courseId = this.$route.query.courseId
    this.axios({
      method: 'GET',
      url: '/series/getCourseInfo?courseId='+this.courseId,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.seriesDetail = res.data.data;
      }
    })
  },
  methods:{
    
    
  }
}
</script>
<style>
.content{
  color: #989AB1;
  padding:0  0.15rem 0.15rem;
  background: #23252E;
  margin-top: 0.15rem;
}
</style>
<style lang="less" scoped>
#CoursesDetailLink{
  .describe{
    padding: 0.15rem;
    color: #F6F7FD;
    background: #23252E;
    >p:first-child{
      font-size: 0.18rem;
    }
    >p:nth-child(2){
      margin-top: 0.1rem;
      font-size: 0.12rem;
      color: #989AB1;
    }
    >p:last-child{
      color: #989AB1;
      margin-top: 0.1rem;
    }
  }
 .top{
  display: flex;
  align-items: center;
  justify-content: center;
   
 }
    
}
</style>